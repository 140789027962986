import React, { useState } from "react";
import axios from "axios";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  FacebookIcon,
  XIcon,
  LinkedinIcon,
  TelegramIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import "./Footer.css"; // Ensure you import your CSS file

const Footer = () => {
  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const scriptURL = process.env.REACT_APP_GOOGLE_SCRIPT_URL_NEWSLETTER;

    try {
      const response = await axios.post(
        scriptURL,
        { email },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      alert("Thank you for subscribing!");
      setEmail("");
    } catch (error) {
      console.error("Error submitting form", error);
      alert("Error submitting form, please try again!");
    }
  };

  const shareUrl = "https://databites.app";
  const title = "DataBites - Expand Your F&B Business Faster and Smarter";
  const description =
    "A tool that helps you to make the right Market Entry Strategy, find trusted Business Partners, and comply with the Regulations.";
  const hashtags = ["DataBites", "F&B", "BusinessGrowth"];

  return (
    <footer className="home-footer">
      <div className="home-top">
        <div className="home-left3">
          <span id="newsletter" className="home-text116">
            Stay up to date with our newsletter
          </span>
          <form className="home-subscription" onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Enter your email"
              className="home-textinput input"
              value={email}
              onChange={handleChange}
              required
            />
            <button type="submit" className="home-subscribe">
              <span className="home-text117">Subscribe</span>
            </button>
          </form>
          <span className="home-text118">
            By subscribing to our newsletter you agree with our Terms and
            Conditions.
          </span>
          <div className="home-contact">
            <span className="home-text119">Contact</span>
            <a
              href="mailto:use@active-app.com?subject=Support"
              className="home-link14"
            >
              contact@databites.app
            </a>
          </div>
        </div>
      </div>
      <div className="home-social-share">
        <span className="home-text119">Share:</span>
        <FacebookShareButton
          url={shareUrl}
          quote={description}
          hashtag={`#${hashtags[0]}`}
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl} title={title} hashtags={hashtags}>
          <XIcon size={32} round />
        </TwitterShareButton>
        <LinkedinShareButton
          url={shareUrl}
          title={title}
          summary={description}
          source="DataBites"
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
        <TelegramShareButton url={shareUrl} title={title} hashtags={hashtags}>
          <TelegramIcon size={32} round />
        </TelegramShareButton>
        <EmailShareButton url={shareUrl} subject={title} body={description}>
          <EmailIcon size={32} round />
        </EmailShareButton>
      </div>
      <div className="home-bottom">
        <span className="home-text137">Copyright © DataBites - 2024</span>
      </div>
    </footer>
  );
};

export default Footer;
