import React, { useEffect, useRef, useState } from "react";

const GifInView = ({ src, placeholder, className, alt }) => {
  const [inView, setInView] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setInView(true);
            observer.disconnect(); // Stop observing after the GIF is in view
          }
        });
      },
      {
        threshold: 0.1, // Trigger when 10% of the element is visible
      }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  return (
    <div className={className} ref={imgRef}>
      <img
        alt={alt}
        src={inView ? src : placeholder}
        className="home-cards-image1"
      />
    </div>
  );
};

export default GifInView;
