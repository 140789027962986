import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import "./carouselBanner.css"; // Ensure you import your CSS file

const CarouselBanner = () => {
  return (
    <div className="carousel-banner-container">
      <Carousel
        showThumbs={false}
        autoPlay
        infiniteLoop
        showStatus={false}
        className="carousel-banner"
        interval="5000"
        showArrows={false}
        transitionTime="1000"
      >
        <a
          href="https://ignite.aaltoes.com/"
          className="carousel-banner-slide"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/imgs/ignite_logo.svg"
            alt="Ignite Accelerator"
            style={{ width: "auto", marginLeft: "250px", height: "140px" }}
          />
          <div className="carousel-banner-content">
            <p className="carousel-banner-text">
              DataBites is proud to be part of the Ignite Accelerator, fostering
              innovation and entrepreneurship.
            </p>
          </div>
        </a>
        <a
          href="https://www.aaltoes.com/"
          className="carousel-banner-slide"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/imgs/aaltoes_logo.png"
            alt="Aaltoes"
            style={{ width: "auto", marginLeft: "170px", height: "140px" }}
          />
          <div className="carousel-banner-content">
            <p className="carousel-banner-text">
              In collaboration with Aalto Entrepreneurship Society (Aaltoes),
              driving the next generation of entrepreneurs.
            </p>
          </div>
        </a>
      </Carousel>
    </div>
  );
};

export default CarouselBanner;
