import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "./userModal";
import "./team2.css";

const Team2 = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});

  const handleOpenModal = (member) => {
    setSelectedMember(member);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const members = [
    {
      name: props.member1,
      job: props.member1Job,
      content: props.member1Content,
      src: props.member1Src,
      alt: props.member1Alt,
      link: "https://www.linkedin.com/in/sarah-miselly/",
    },
    {
      name: props.member2,
      job: props.member2Job,
      content: props.member2Content,
      src: props.member2Src,
      alt: props.member2Alt,
      link: "https://www.linkedin.com/in/finylinjaya/",
    },
    {
      name: props.member3,
      job: props.member3Job,
      content: props.member3Content,
      src: props.member3Src,
      alt: props.member3Alt,
      link: "https://www.linkedin.com/in/arielmld/",
    },
    {
      name: props.member4,
      job: props.member4Job,
      content: props.member4Content,
      src: props.member4Src,
      alt: props.member4Alt,
      link: "https://www.linkedin.com/in/jaurasmaa",
    },
  ];

  return (
    <div className={`team2-team8 thq-section-padding ${props.rootClassName} `}>
      <div className="team2-max-width thq-section-max-width">
        <div className="team2-section-title">
          <div className="team2-content">
            <h2 className="team2-text thq-heading-2">{props.heading1}</h2>
            <p className="team2-text01 thq-body-large">{props.content2}</p>
          </div>
        </div>
        <div className="team2-content1">
          <div className="team2-content2">
            <div className="team2-row">
              {members.map((member, index) => (
                <div
                  className="team2-card"
                  key={index}
                  onClick={() => handleOpenModal(member)}
                >
                  <img
                    alt={member.alt}
                    src={member.src}
                    className="thq-img-ratio-1-1 team2-placeholder-image"
                  />
                  <div className="team2-content3">
                    <div className="team2-title">
                      <span className="team2-text02 thq-body-small">
                        {member.name}
                      </span>
                      <span className="team2-text03 thq-body-small">
                        {member.job}
                      </span>
                    </div>
                  </div>
                  <div className="team2-social-icons">
                    <a
                      href={member.link}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="team2-link"
                      onClick={(e) => e.stopPropagation()} // Prevent opening modal when clicking the link
                    >
                      <svg
                        viewBox="0 0 877.7142857142857 1024"
                        className="team2-icon thq-icon-small"
                      >
                        <path
                          d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"
                          className=""
                        ></path>
                      </svg>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onClose={handleCloseModal}
        member={selectedMember}
      />
    </div>
  );
};

Team2.defaultProps = {
  member1: "Sarah Miselly",
  member1Job: "CEO",
  member1Content:
    "5+ years driving Growth & Impact:  Project & People Management, Training,  Sales & Marketing across diverse organizations (NGOs, IT Companies and International School).",
  member1Src: "/imgs/12-600w.png",
  member1Alt: "Sarah Miselly",
  member2: "Finy Mercialie Linjaya",
  member2Job: "Full Stack Data Scientist",
  member2Content:
    "5+ years Data Specialist in various industries at leading companies, from ‘Global Big Pharma’ to ‘SEA Unicorn E-commerce’.",
  member2Src: "/imgs/11-600w.png",
  member2Alt: "Finy Mercialie Linjaya",
  member3: "Ariel Malada",
  member3Job: "Full Stack Developer",
  member3Content:
    "5+ years SW Developer with UX-drive solutions across various industries, including Legal Tech and Real Estate Tech.",
  member3Src: "/imgs/10-600w.png",
  member3Alt: "Ariel Malada",
  member4: "Joonatan Aurasmaa",
  member4Job: "Full Stack Developer",
  member4Content:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.",
  member4Src: "/imgs/9-600w.png",
  member4Alt: "Joonatan Aurasmaa",
  heading1: "Our team",
  content2: "Dedicated Experts at Your Service",
  rootClassName: "",
};

Team2.propTypes = {
  member1: PropTypes.string,
  member1Job: PropTypes.string,
  member1Content: PropTypes.string,
  member1Src: PropTypes.string,
  member1Alt: PropTypes.string,
  member2: PropTypes.string,
  member2Job: PropTypes.string,
  member2Content: PropTypes.string,
  member2Src: PropTypes.string,
  member2Alt: PropTypes.string,
  member3: PropTypes.string,
  member3Job: PropTypes.string,
  member3Content: PropTypes.string,
  member3Src: PropTypes.string,
  member3Alt: PropTypes.string,
  member4: PropTypes.string,
  member4Job: PropTypes.string,
  member4Content: PropTypes.string,
  member4Src: PropTypes.string,
  member4Alt: PropTypes.string,
  heading1: PropTypes.string,
  content2: PropTypes.string,
  rootClassName: PropTypes.string,
};

export default Team2;
