import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./mobileForm.css";

const MobileForm = (props) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companySize: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const scriptURL = process.env.REACT_APP_GOOGLE_SCRIPT_URL;

    try {
      const response = await axios.post(scriptURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("Thank you, we'll be in touch within 24 hours!");
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        companySize: "",
      });
    } catch (error) {
      console.error("Error submitting form");
      toast.error("Error submitting form, please try again!");
    }
  };

  return (
    <div className="mobile-form-container">
      <header className="mobile-form-header">
        <h1 className="mobile-form-heading">DataBites</h1>
      </header>
      <div className="mobile-form-content">
        <h2 className="mobile-form-title">Get in touch</h2>
        <form className="mobile-form-form" onSubmit={handleSubmit}>
          <div className="mobile-form-input-group">
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              className="mobile-form-input"
              autoComplete="given-name"
              maxLength="40"
              minLength="1"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              autoComplete="family-name"
              className="mobile-form-input"
              maxLength="40"
              minLength="1"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Work Email"
              className="mobile-form-input"
              maxLength="40"
              minLength="1"
              autoComplete="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <select
              name="companySize"
              className="mobile-form-select"
              value={formData.companySize}
              onChange={handleChange}
              required
              defaultValue=""
            >
              <option value="" disabled>
                Company Size
              </option>
              <option value="1-50">1-50</option>
              <option value="50-200">51-200</option>
              <option value="201-2000">201-2000</option>
              <option value="2000+">2000+</option>
            </select>
          </div>
          <button type="submit" className="mobile-form-button">
            Submit
          </button>
        </form>
        <p className="mobile-form-info">
          For more information, please visit our website on a desktop version.
        </p>
      </div>
      <footer className="mobile-form-footer">
        <p>&copy; {new Date().getFullYear()} DataBites. All rights reserved.</p>
      </footer>
      <ToastContainer />
    </div>
  );
};

MobileForm.propTypes = {
  companyDescription: PropTypes.string,
};

export default MobileForm;
