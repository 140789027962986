import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./contact-form3.css";

const ContactForm3 = (props) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companySize: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const scriptURL = process.env.REACT_APP_GOOGLE_SCRIPT_URL;

    try {
      const response = await axios.post(scriptURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Thank you, we'll be in touch within 24 hours!!!!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        companySize: "",
      });
    } catch (error) {
      console.error("Error submitting form");
      toast.error("Error submitting form, please try again!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  };

  return (
    <div
      className={`contact-form3-contact9 thq-section-padding ${props.rootClassName}`}
    >
      <ToastContainer
        position="top-center"
        autoClose={5000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="colored"
      />
      <div className="thq-section-max-width thq-flex-row contact-form3-max-width">
        <img
          alt={props.imageAlt1}
          src={props.imageSrc1}
          className="contact-form3-image"
        />
        <div className="contact-form3-content thq-flex-column">
          <div className="contact-form3-section-title thq-card">
            <h2 className="contact-form3-text">{props.heading1}</h2>
            <span className="contact-form3-text1">{props.content1}</span>
          </div>
          <form className="contact-form3-form thq-card" onSubmit={handleSubmit}>
            <div className="contact-form3-container">
              <input
                type="text"
                id="first-name-input"
                name="firstName"
                placeholder="First Name"
                autoComplete="given-name"
                className="contact-form3-textinput thq-grid-2 input"
                maxLength="40"
                minLength="1"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                id="last-name-input"
                name="lastName"
                placeholder="Last Name"
                autoComplete="family-name"
                maxLength="40"
                minLength="1"
                className="contact-form3-textinput1 thq-grid-2 input"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="contact-form3-container1">
              <input
                type="email"
                id="email-input"
                name="email"
                maxLength="40"
                minLength="4"
                placeholder="Work Email"
                autoComplete="email"
                className="contact-form3-textinput2 input"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <div className="contact-form3-container2">
                <select
                  id="company-size-select"
                  name="companySize"
                  className="contact-form3-select input"
                  value={formData.companySize}
                  onChange={handleChange}
                  required
                  defaultValue=""
                >
                  <option value="" disabled>
                    Company Size
                  </option>
                  <option value="1-50">1-50</option>
                  <option value="50-200">51-200</option>
                  <option value="201-2000">201-2000</option>
                  <option value="2000+">2000+</option>
                </select>
              </div>
            </div>
            <button type="submit" className="contact-form3-button">
              <span className="contact-form3-action1 thq-body-small">
                Submit
              </span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

ContactForm3.defaultProps = {
  textinputPlaceholder: "placeholder",
  imageAlt1: "image",
  action: "Submit",
  heading1: "Unlock your global potential — reach out to us now!",
  imageSrc1: "/imgs/logo_trasparent.png",
  textinputMaxlenght: "40",
  imageAlt: "Image1",
  imageSrc: "1d111315-2b29-4314-b175-0ba8510f2fbe",
  content1: "Want to hear more? Our team will be in touch within 24 hours.",
  rootClassName: "",
  text1: "Enter your name",
};

ContactForm3.propTypes = {
  textinputPlaceholder: PropTypes.string,
  imageAlt1: PropTypes.string,
  action: PropTypes.string,
  heading1: PropTypes.string,
  imageSrc1: PropTypes.string,
  textinputMaxlenght: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  content1: PropTypes.string,
  rootClassName: PropTypes.string,
  text1: PropTypes.string,
};

export default ContactForm3;
